'use client';
/* eslint-disable @next/next/no-page-custom-font */

// Import all the fortawesome styles at once
import { config } from '@fortawesome/fontawesome-svg-core';
config.autoAddCss = false;

import '../styles/common.scss';

import './global.scss';

import NoSSR from '@/components/NoSSR';
import { redirect } from 'next/navigation';
import NextAdapterApp from 'next-query-params/app';
import { QueryParamProvider } from 'use-query-params';
import { PropsWithChildren } from 'react';
import { MsalWrapper } from '@/contexts/TokenContext/MsalWrapper';
import Header from './header/page';
import AppInsightsWrapper from '@/components/AppInsightsWrapper/AppInsightsWrapper';
import { emptyStringOrDefault } from '@/common/util/strings/emptyStringOrDefault';
import NextTopLoader from 'nextjs-toploader';
import { FullStoryUserLogger } from '@/components/FullStory/FullStoryUserLogger';
import { FullStoryWrapper } from '@/components/FullStory/FullStoryWrapper';
import { getFullStoryOptions } from '@/components/FullStory/fullStoryConfig';

// For local development, redirect to development hostname
function isLocalhost(): boolean {
    if (typeof window === 'undefined') {
        return false;
    }
    const hostname = emptyStringOrDefault(window?.location?.hostname);
    return hostname.indexOf('localhost') !== -1 || hostname.indexOf('127.0.0.1') !== -1;
}

export default function RootLayout(props: PropsWithChildren): JSX.Element {
    if (isLocalhost()) {
        redirect('https://dev.outageadmin.sites.dteenergy.com/');
    }

    const fullStoryOptions = getFullStoryOptions();

    return (
        <html lang="en">
            <head>
                <title>DTE Outage</title>
                <link rel="icon" href="/favicon.ico" sizes="any" />
                <link
                    rel="stylesheet"
                    href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap"
                    crossOrigin="anonymous"
                    referrerPolicy="no-referrer"
                />
                <link
                    rel="stylesheet"
                    href="https://js.arcgis.com/4.28/esri/themes/light/main.css"
                    crossOrigin="anonymous"
                    referrerPolicy="no-referrer"
                />
            </head>
            <body>
                <NoSSR>
                    <AppInsightsWrapper>
                        <FullStoryWrapper options={fullStoryOptions}>
                            <NextTopLoader crawlSpeed={50} speed={50} zIndex={9999} />
                            <MsalWrapper>
                                <FullStoryUserLogger />
                                <Header />
                                <QueryParamProvider adapter={NextAdapterApp}>{props.children}</QueryParamProvider>
                            </MsalWrapper>
                        </FullStoryWrapper>
                    </AppInsightsWrapper>
                </NoSSR>
            </body>
        </html>
    );
}
