import { FullStory, init, SnippetOptions } from '@fullstory/browser';
import { createContext, PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';
import { FSApi } from '@fullstory/snippet';

export interface ReadyResponse {
    sessionUrl: string;
    settings: Readonly<object>;
}

export interface FullStoryContextType {
    initialized: boolean;
    ready: boolean;
    readyResponse?: ReadyResponse;
}

const FullStoryContext = createContext<FullStoryContextType | undefined>(undefined);

interface FullStoryWrapperProps extends PropsWithChildren {
    options: SnippetOptions;
}

export function FullStoryWrapper(props: FullStoryWrapperProps) {
    const options = props.options;

    const initialized = useRef(false);
    const ready = useRef(false);
    const readyResponse = useRef<ReadyResponse>(undefined);

    const getContext = (): FullStoryContextType => {
        const contextValue: FullStoryContextType = {
            initialized: initialized.current,
            ready: ready.current,
            readyResponse: readyResponse.current,
        };
        return contextValue;
    };

    const [context, setContext] = useState(getContext());

    useEffect(() => {
        // Need options to initialize Full Story
        if (!options) {
            console.log('Full Story: No options to initialize');
            return;
        }

        // Ensure we only initialized once
        if (initialized.current) {
            return;
        }
        initialized.current = true;

        // TODO: this doesn't seem to be working
        const readyCallback = (data: ReadyResponse) => {
            console.log('Full story is ready');
            ready.current = true;
            readyResponse.current = data;

            const newContext = getContext();
            setContext(newContext);
        };

        init(options, readyCallback);
        const newContext = getContext();
        setContext(newContext);
    }, [options]);

    return (
        <>
            <FullStoryContext.Provider value={context}>{props.children}</FullStoryContext.Provider>
        </>
    );
}

export const useFullStoryContext = (): FullStoryContextType => {
    const context = useContext(FullStoryContext);
    if (!context) {
        throw new Error('useFullStoryContext must be used within FullStoryReact');
    }
    return context;
};

export function useFullStory(): FSApi {
    const context = useFullStoryContext();
    const initialized = context.initialized;

    // TODO: add a shim to queue things up if requests are made before the API is ready
    const [fullStory, setFullStory] = useState<FSApi>(undefined);

    useEffect(() => {
        // TODO: this should probably look at both initialized and ready (* if we can get the ready callback to work)
        if (initialized) {
            setFullStory(FullStory);
        } else {
            setFullStory(undefined);
        }
    }, [initialized]);

    return fullStory;
}
